<template>
  <div
      class="status-column">
    <template v-if="scope.row.timeBlocks">
      <ul>
        <li
            v-for="(timeBlock, index) in formattedTimeBlocks"
            :key="`timeBlock-${index}`">
          <div class="time">
            <strong>{{ timeBlock.startTime | formatTime }}</strong> to <strong>{{ timeBlock.endTime | formatTime }}</strong>
          </div>
          <div class="slots">| <strong>{{ timeBlock.bookingSlots }}</strong> slot<template v-if="timeBlock.bookingSlots != 1">s</template></div>
        </li>
      </ul>
    </template>
  </div>  
</template>
<script>
import moment from 'moment';

export default {
  name: "BookingTimeBlocksColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ")
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatTime(date) {
      return moment(date).format('h:mma')
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedTimeBlocks() {
      return this.scope.row.timeBlocks
    }
  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    }
  }
}
</script>

<style lang="scss" scoped>

  .status-column {
    margin: 0 auto;
    .el-tag {
      min-width: 90%;
      margin: auto;
      text-align: center;
      box-shadow: var(--box-shadow-container-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      display: flex;

      div {
        &.time {
          margin-right: 10px;
        }
      }
    }
  }
</style>
