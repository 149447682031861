<template>
  <div class="thank-you-view-container">
    <div class="thank-you-message">
      <div class="thank-you-icon">
        <CoreIcon
            icon="CheckCircleIcon"
            size="large"
            color="success" />
      </div>
      <div class="thank-you-text">
        <h1>Thank you for your booking</h1>
        <p>Your booking has been successfully submitted.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouView",
}
</script>
<style lang="scss" scoped>
// create css
.thank-you-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .thank-you-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .thank-you-icon {
      margin-bottom: 1em;
    }
    .thank-you-text {
      text-align: center;
      h1 {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 0.5em;
      }
      p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 0.5em;
      }
    }
  }
}
</style>