<template>
  <div>
    <core-data-table-v2
        :model="bookingNoticesModel"
        :columns="columns"
        table-name="booking-notices-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { BookingNotice } from "@/Modules/OdysseyModels"
import columns from "../components/BookingNoticeTableView/columns"
export default {
  name: "BookingNoticesTableView",
  computed: {
    bookingNoticesModel() {
      return BookingNotice
    },
    columns() {
      return columns
    }
  }
}
</script>
