<template>
  <div
      v-if="bookingNotice !== null"
      class="max-width-container">
    <div class="booking-details-header">
      <h1>{{ bookingNotice.noticeType | formatUnderscore }}</h1>
      <h2>{{ bookingSite.street }}, {{ bookingSite.city }}</h2>
      <h3 v-html="bookingNoticeDescription" />
      <h4>{{ bookingNotice.date | formatDate }}</h4>
    </div>

    <div class="booking-slots">
      <table
          v-for="(block, index) in bookingNotice.timeBlocks"
          :key="index"
          class="table table-bordered">
        <tbody>
          <tr>
            <td style="width: 35%">
              <tr>
                <td class="header">
                  Description
                </td>
              </tr>
              <tr>
                <td>
                  <div
                      v-html="formatDescription(block.description)" />
                </td>
              </tr>
            </td>

            <td style="width: 35%">
              <tr>
                <td
                    class="header"
                    colspan="3">
                  Inspection Time
                </td>
              </tr>
              <tr>
                <td
                    class="border-right"
                    style="width:33%">
                  {{ block.startTime | formatTime }}
                </td>
                <td style="text-align: center">
                  <strong>to</strong>
                </td>
                <td>{{ block.endTime | formatTime }}</td>
              </tr>
            </td>

            <td style="width: 30%;">
              <template v-if="block.used < block.bookingSlots">
                <CoreButton
                    class="button-red"
                    @click="handleBookingClick(block)">
                  Book
                </CoreButton>
              </template>
              <template v-else>
                Bookings unavailable
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { BookingNotice, Site, BookingPage } from "@/Modules/OdysseyModels"
import moment from 'moment'

export default {
  name: "BookingPagesTableView",
  filters: {
    formatUnderscore(string) {
      // replace globally
      const newString = string.replace(/_/g, ' ')
      // make each word uppercase
      return newString.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })

    },
    formatTime(data) {
      return moment(data).format('h:mma')
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
  },
  data() {
    return {
      bookingNotice: null,
      site: null
    }
  },
  computed: {
    bookingSite() {
      return Site.find(this.bookingNotice.siteId)
    },
    bookingNoticeDescription() {
      const description = this.bookingNotice.description
      return description.replace(/(?:\n)/g, '<br />')
    }
  },
  async mounted() {
    const bookingNoticeId = this.$route.params.id
    if (bookingNoticeId) {
      await BookingNotice.api().fetchById(bookingNoticeId)
      this.bookingNotice = BookingNotice.find(bookingNoticeId)
      await Site.api().fetchById(this.bookingNotice.siteId)
    }
  },
  methods: {
    formatDescription(description) {
      return description.replace(/(?:\n)/g, '<br />')
    },
    handleBookingClick(block) {

      const slot = {
        bookingNoticeId: this.bookingNotice.id,
        startTime: block.startTime,
        endTime: block.endTime,
        bookingDetails: {
          site: this.bookingSite,
          bookingNotice: this.bookingNotice
        }
      }
      console.log('slot', slot)
      new BookingPage().Actions.bookSlotDialog(slot)
    }
  }
}
</script>

<style lang="scss" scoped>
h1, h2, h3, h4{
  color: #78787a;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 40px;
  font-weight: normal;
}
h2 {
  font-size: 40px;
}

h3, h4{
  font-size: 30px;
}
.booking-details-header {
  margin-top: 100px;
  text-align: center;
}
.max-width-container {
  max-width: 1200px;

  table {
    border: 1px solid #ea273a;
    text-align: left;
    margin: var(--margin-xl);
    border-radius: 20px;
    overflow: hidden;
    color: #78787a;
    font-size: 18px;
    padding: 10px 0;
    width: 90%;

    tr {
      transition: ease-in-out all 0.2s;


    }
    td {
      padding: 0 10px;
      width: 42%;
      vertical-align: top;

      &:first-child {
        border-right: 1px solid #ea273a;
      }

      &.header {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid #e8e8e9;
      }

      tr {
        td {
          border: none;

          &:first-child {
            border-right: none;
          }

        }
      }
    }

  }
  .site-address {
   background: #f1f3fa;
    padding: var(--padding-s) var(--padding-l);
    dl {
      display: flex;
      justify-content: flex-start;

      dt {
        font-weight: bold;
        min-width: 150px
      }

      dd {
      }
    }
  }
}

.el-button.button-red {
  background: #ea273a !important;
  color: white;
  text-shadow: none;
  font-size: 30px;
  border-radius: 0 15px 15px 0 !important;
  padding: 15px 40px !important;
  width: 100%;
}

// media query for mobile phone
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 20px;
    font-weight: normal;
  }
  h2 {
    font-size: 15px;
  }

  h3, h4{
    font-size: 12px;
  }

  .booking-details-header {
    margin-top: 0px;
    text-align: center;
  }

  table {
    font-size: 12px !important;
    width: auto !important;
  }

  .el-button.button-red {
    font-size: 15px;
    border-radius: 0 15px 15px 0 !important;
    padding: 50px 10px !important;
    width: 100%;
  }
}
</style>
