const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Sites",
    options: [
      {
        label: "Site Title",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.title",
        inputType: "text",
        props: {
          placeholder: "Search by Site Title"
        }
      },
      {
        label: "Contact Name",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.contactName",
        inputType: "text",
        props: {
          placeholder: "Search by Contact Name"
        }
      },
      {
        label: "Contact Phone",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.mobileNumber",
        inputType: "text",
        props: {
          placeholder: "Search by Phone Number"
        }
      },
      {
        label: "Contact Email",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.email",
        inputType: "text",
        props: {
          placeholder: "Search by Email"
        }
      },
      {
        label: "Creation Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date"
        }
      },
    ]
  },
  {
    label: "Location",
    options: [
      {
        label: "City",
        queryType: "search",
        urlKey: "filter.city",
        inputType: "text",
        props: {
          placeholder: "Search by City"
        }
      },
      {
        label: "City",
        queryType: "search",
        urlKey: "filter.state",
        inputType: "text",
        props: {
          placeholder: "Search by State"
        }
      },
      {
        label: "Postcode",
        queryType: "search",
        urlKey: "filter.postcode",
        inputType: "text",
        props: {
          placeholder: "Search by postcode"
        }
      }
    ]
  }
]