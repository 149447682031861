<template>
  <svg
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
      <path
          :fill="color"
          d="m561.12 560c-3.3594 0-6.7188-2.8008-6.7188-6.7188v-52.641c0-32.48-14-63.84-39.199-84.559l-91.281-78.398c-2.8008-2.2383-2.8008-6.7188-0.55859-9.5195 2.2383-2.8008 6.7188-2.8008 9.5195-0.55859l91.281 78.398c28 23.52 43.68 58.238 43.68 94.641v52.641c-0.003906 3.9141-3.3633 6.7148-6.7227 6.7148z" />
      <path
          :fill="color"
          d="m138.88 560c-3.3594 0-6.7188-2.8008-6.7188-6.7188v-52.078c0-36.961 16.238-71.68 44.238-95.762l92.961-77.84c2.8008-2.2383 6.7188-2.2383 9.5195 0.55859 2.2383 2.8008 2.2383 6.7188-0.55859 9.5195l-92.961 78.398c-25.199 21.281-39.762 52.641-39.762 85.68v52.078c0.003906 3.3633-2.7969 6.1641-6.7188 6.1641z" />
      <path
          :fill="color"
          d="m351.12 371.28c-72.238 0-131.04-69.441-131.04-155.12 0-3.3594 2.8008-6.7188 6.7188-6.7188 3.3594 0 6.7188 2.8008 6.7188 6.7188 0 78.398 53.199 142.24 118.16 142.24 64.961 0 118.16-63.84 118.16-142.24 0-3.3594 2.8008-6.7188 6.7188-6.7188 3.9219 0 6.7188 2.8008 6.7188 6.7188-0.55859 85.121-59.918 155.12-132.16 155.12z" />
      <path
          :fill="color"
          d="m492.8 201.6h-285.6c-3.3594 0-6.7188-2.8008-6.7188-6.7188v-22.398c0-12.879 10.641-23.52 23.52-23.52h27.441c17.359 0 33.602-7.2812 44.801-20.16 11.199-12.879 16.801-29.68 14.559-47.039l-6.1602-52.641c-1.1211-7.2812 1.6797-15.121 6.7188-20.719 5.0391-5.6016 12.32-8.9609 19.602-8.9609h38.641c7.2812 0 14.559 3.3594 19.602 8.9609 5.0391 5.6016 7.2812 13.441 6.7188 20.719l-6.7227 53.195c-2.2383 16.801 3.3594 34.16 14.559 47.039 11.199 12.879 28 20.16 44.801 20.16h27.441c12.879 0 23.52 10.641 23.52 23.52v22.398c0 3.3633-2.8008 6.1641-6.7188 6.1641zm-279.44-13.441h272.72v-15.68c0-5.6016-4.4805-10.078-10.078-10.078h-27.441c-20.719 0-40.879-8.9609-54.879-24.641-14-15.68-20.16-36.398-17.922-57.121l6.7227-52.641c0.55859-3.9219-0.55859-7.2812-3.3594-10.641-2.8008-2.8008-6.1602-4.4805-10.078-4.4805h-38.641c-3.9219 0-7.2812 1.6797-10.078 4.4805-2.8008 2.8008-3.9219 6.7188-3.3594 10.641l6.1602 52.641c2.2383 20.719-3.9219 41.441-17.922 57.121-14 15.68-33.602 24.641-54.879 24.641h-27.441c-5.6016 0-10.078 4.4805-10.078 10.078l-0.003907 15.68z" />
      <path
          :fill="color"
          d="m476 162.4c-3.3594 0-6.7188-2.8008-6.7188-6.7188v-39.762c0-44.238-36.398-80.641-80.641-80.641-3.3594 0-6.7188-2.8008-6.7188-6.7188 0-3.918 3.3594-6.1602 7.2773-6.1602 51.52 0 93.52 42 93.52 93.52v39.762c0 3.9219-2.7969 6.7188-6.7188 6.7188z" />
      <path
          :fill="color"
          d="m224 162.4c-3.3594 0-6.7188-2.8008-6.7188-6.7188v-39.762c0.55859-51.52 42.559-93.52 94.078-93.52 3.3594 0 6.7188 2.8008 6.7188 6.7188 0 3.9219-2.8008 6.7188-6.7188 6.7188-44.238 0-80.641 36.398-80.641 80.641v39.762c0 3.3633-2.7969 6.1602-6.7188 6.1602z" />
      <path
          :fill="color"
          d="m432.88 560c-3.3594 0-6.7188-2.8008-6.7188-6.7188 0-141.12 53.199-176.4 55.441-177.52 2.8008-1.6797 7.2812-1.1211 8.9609 2.2383 1.6797 2.8008 1.1211 7.2812-2.2383 8.9609-0.55859 0.55859-49.281 34.16-49.281 166.88-0.003907 3.3594-2.8047 6.1602-6.1641 6.1602z" />
      <path
          :fill="color"
          d="m267.68 560c-3.3594 0-6.7188-2.8008-6.7188-6.7188 0-132.72-49.281-166.32-49.281-166.88-2.8008-2.2383-3.9219-6.1602-1.6797-8.9609 1.6797-2.8008 6.1602-3.9219 8.9609-2.2383 2.2383 1.6797 55.441 36.961 55.441 177.52-0.003906 4.4766-2.8008 7.2773-6.7227 7.2773z" />

    </g>
  </svg>
</template>

<script>
export default {
  name: "NoticeTemplateBuilderIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
</style>
