<template>
  <div class="form">
    <el-form label-position="top">
      <el-form-item label="Please select the type of Notice">
        <el-select
            v-model="noticeType">
          <el-option
              v-for="(option, index) in noticeTypeOptions"
              :key="`notice-${index}`"
              :label="option.label"
              :value="option.value" />
        </el-select>
      </el-form-item>
    </el-form>

    <CoreFormBuilderV2
        v-if="formConfig"
        :key="noticeType"
        :data="formData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="dialogProps.mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { BookingNotice } from "@/Modules/OdysseyModels"
  // import formConfig from "./formConfig"
  import fireSafetyRepairsFormConfig from "./FireSafetyRepairsForm/fireSafetyRepairsFormConfig"

  export default {
    name: "BookingNoticeForm",
    props: {
      dialogProps: { type: Object, required: true },
      formData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        noticeType: 'routine_fire_inspection',
        noticeTypeOptions: [
          {
            label: 'Routine Fire Inspection',
            value: 'routine_fire_inspection'
          },
          {
            label: 'Fire Safety Repair(s)',
            value: 'fire_safety_repairs'
          },
        ]

      }
    },
    computed: {
      formConfig() {
        return fireSafetyRepairsFormConfig
      },
    },
    mounted() {
      if (this.formData.noticeType) {
        this.noticeType = this.formData.noticeType
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const { mode } = this.dialogProps
          const flatFormData = Form.getFlatData()
          flatFormData.noticeType = this.noticeType

          if (mode === "create") {
            await BookingNotice.api().create(flatFormData)
          } else if (mode === "edit") {
            flatFormData.id = this.formData.id
            await BookingNotice.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your Booking Notice has been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          await BookingNotice.api().fetchAll();
          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>