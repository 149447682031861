import BookingNoticeStatusColumn from "./BookingNoticeStatusColumn";
import BookingTimeBlocksColumn from "./BookingTimeBlocksColumn";
import SiteColumn from "./SiteColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "Site",
      label: "Site",
      Component: SiteColumn
    },
    {
      prop: "description",
      label: "Description",
    },
    {
      prop: "NoticeType",
      label: "Notice Type",
    },
  {
    prop: "NoticeDate",
    label: "Notice Date",
  },
  {
    prop: "timeBlocks",
    label: "Time Blocks",
    Component: BookingTimeBlocksColumn
  },
  {
    prop: "active",
    label: "Status",
    Component: BookingNoticeStatusColumn
  }
]

export default defaultColumns