import SiteManagementStatusColumn from "./SiteManagementStatusColumn";
import SiteManagementColorColumn from "./SiteManagementColorColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "title",
      label: "Site"
    },
    {
      prop: "Address",
      label: "Address",
    },
    {
      prop: "SiteContact",
      label: "Site Contact",
    },
    {
      prop: "active",
      label: "Status",
      Component: SiteManagementStatusColumn
    }
  ]

export default defaultColumns