<template>
  <div
      v-if="bookingNotice !== null"
      class="max-width-container">
    <h4>
      Booking Summary for Booking #{{ bookingNotice.id }} | <strong>{{ bookingNotice.date | formatDate }}</strong>
    </h4>
    <div

        v-if="bookingSite"
        v-loading="bookingSite === null"
        class="site-address">
      <h5>Site Details</h5>
      <strong>{{ bookingSite.title }}</strong>

      <dl>
        <dt>Address: </dt>
        <dd>{{ bookingSite.street }}, {{ bookingSite.city }} {{ bookingSite.zip }} {{ bookingSite.state }} </dd>
      </dl>
      <dl v-if="bookingSite.contactEmail">
        <dt>Contact Email: </dt>
        <dd>{{ bookingSite.contactEmail }}</dd>
      </dl>
      <dl v-if="bookingSite.contactPhone">
        <dt>Contact Phone: </dt>
        <dd>{{ bookingSite.contactPhone }}</dd>
      </dl>
    </div>
    <div class="booking-slots">
      <h5>Booking Slots</h5>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Schedule</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Description</th>
            <th>Slots (Total/Used)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
              v-for="(block, index) in bookingNotice.timeBlocks"
              :key="index"
              :class="{ highlight: index === bookingIndex }">
            <td
                style="width: 5%;">
              {{ index + 1 }}
            </td>
            <td style="width: 10%">
              {{ block.startTime | formatTime }}
            </td>
            <td style="width: 10%">
              {{ block.endTime | formatTime }}
            </td>
            <td style="width: 10%">
              {{ block.description | formatUnderscore }}
            </td>
            <td style="width: 10%">
              {{ block.bookingSlots }} / {{ block.used }}
            </td>
            <td style="width: 5%">
              <div class="action-wrapper">
                <template v-if="block.used > 0">
                  <CoreButton
                      icon="el-icon-view"
                      @click="handleShowBookings(block, index)">
                    Show Bookings
                  </CoreButton>
                  <CoreButton
                      icon="el-icon-download"
                      @click="handleExportBookings(block)">
                    Export Bookings
                  </CoreButton>
                </template>
                <template v-else>
                  No Bookings available yet
                </template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table
          v-if="bookings"
          v-loading="isLoadingBookings"
          class="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Unit Number</th>
            <th>Level</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Comments</th>
            <th>Last Email Notification</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
              v-for="(booking, index) in bookings"
              :key="index">
            <td style="width: 10%">
              {{ booking.name }}
            </td>
            <td style="width: 10%">
              {{ booking.unitNumber }}
            </td>
            <td style="width: 10%">
              {{ booking.level }}
            </td>
            <td style="width: 5%">
              {{ booking.phoneNumber }}
            </td>
            <td style="width: 5%">
              {{ booking.email }}
            </td>
            <td style="width: 10%">
              {{ booking.comments }}
            </td>
            <td style="width: 5%">
              {{ booking.lastMailNotification | formatLastEmailDate }}
            </td>
            <td style="width: 5%;">
              <div
                  class="action-wrapper">
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Resend Booking Confirmation"
                    placement="top-end">
                  <el-popconfirm
                      title="Are you sure you want to resend the Booking Confirmation for this client?"
                      confirm-button-text="Send Confirmation"
                      @confirm="resendBookingConfirmation(booking.id)">
                    <span
                        slot="reference"
                        class="danger"><i
                            class="el-icon-message"
                            style="cursor: pointer" /></span>
                  </el-popconfirm>
                </el-tooltip>

                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Delete this booking"
                    placement="top-end">
                  <el-popconfirm
                      title="Are you sure you want to delete this Booking?"
                      confirm-button-text="Delete Booking"
                      @confirm="deleteBooking(booking.id)">
                    <span
                        slot="reference"
                        class="danger"><i
                            class="el-icon-delete"
                            style="color: #f56c6c; cursor: pointer" /></span>
                  </el-popconfirm>
                </el-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {BookingNotice, Site } from "@/Modules/OdysseyModels"
import moment from "moment/moment";
export default {
  name: "BookingsOverview",
  filters: {
    formatUnderscore(string) {
      return string.replace("_", " ")
    },
    formatTime(data) {
      return moment(data).format('H:mma')
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatLastEmailDate(date) {
       if (date === '0000-00-00 00:00:00') {
         return "N/A"
       }
      return moment(date).format('DD/MM/YYYY H:mma')
    }
  },
  data() {
    return {
      bookingNotice: null,
      site: null,
      bookings: null,
      bookingIndex: null,
      selectedBlock: null,
      isLoadingBookings: false,
    }
  },
  computed: {
    bookingSite() {
      return Site.find(this.bookingNotice.siteId)
    }
  },
  async mounted() {
    await this.getBookings()
  },
  methods: {
    async getBookings() {
      const bookingNoticeId = this.$route.params.id

      if (bookingNoticeId) {
        await BookingNotice.api().fetchById(bookingNoticeId)
        this.bookingNotice = BookingNotice.find(bookingNoticeId)
        await Site.api().fetchById(this.bookingNotice.siteId)

      }
    },
    async deleteBooking(bookingId) {
      this.isLoadingBookings = true
      const bookingNoticeId = this.$route.params.id

      const deleteBookingResult = await BookingNotice.api().deleteBooking(bookingId)
      if(deleteBookingResult.response.status === 200) {

        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank You",
          message: "The booking has been successfully cancelled.",
          visible: true,
          decay: 3500
        }

        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      }
      await BookingNotice.api().fetchById(bookingNoticeId)
      this.bookingNotice = BookingNotice.find(bookingNoticeId)

      const { block, index } = this.selectedBlock

      await this.handleShowBookings(block, index)
    },
    async resendBookingConfirmation(bookingId) {
      this.isLoadingBookings = true
      const bookingNoticeId = this.$route.params.id

      const resendBookingConfirmationResult = await BookingNotice.api().resendBookingConfirmation(bookingId)
      if(resendBookingConfirmationResult.response.status === 200) {

        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank You",
          message: "The booking confirmation email has been successfully sent.",
          visible: true,
          decay: 3500
        }

        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      }
      await BookingNotice.api().fetchById(bookingNoticeId)
      this.bookingNotice = BookingNotice.find(bookingNoticeId)

      const { block, index } = this.selectedBlock

      await this.handleShowBookings(block, index)
    },
    async handleShowBookings(block, index) {
      this.isLoadingBookings = true
      const bookingNoticeId = this.$route.params.id
      const result = await BookingNotice.api().getBookings(bookingNoticeId, { timeStart: block.startTime, timeEnd: block.endTime })
      this.bookings = result.response.data.data.data
      this.bookingIndex = index
      this.selectedBlock = { block: block, index: index }
      this.isLoadingBookings = false
    },
    async handleExportBookings(block) {
      const bookingNoticeId = this.$route.params.id

      // get local time offset
      const response = await BookingNotice.api().exportBookings(bookingNoticeId, { timeOffset: moment().utcOffset(),siteId: this.bookingSite.id, timeStart: block.startTime, timeEnd: block.endTime })

      console.log(response)
      const result = response.response.data
      const fileLocation = result.fileLocation
      let notificationsPayload

      if (fileLocation) {
        notificationsPayload = {
          component: "Toast",
          type: "download",
          title: "Thank you",
          position: "top-right",
          message: `Your Bookings CSV has been created. Downloaded here: <a href="${fileLocation}" target="_blank">Bookings Export #${bookingNoticeId}</a>`,
          visible: true,
          decay: 0
        }
      } else {
        notificationsPayload = {
          component: "Toast",
          type: "error",
          title: "Error creating Bookings Export",
          message: `Sorry, your Booking Export could not be created.`,
          visible: true,
          decay: 3500
        }
      }
      this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

    }
  }
}
</script>

<style lang="scss" scoped>

.action-wrapper {
  display: flex;
  gap: 10px;
}
.max-width-container {
  max-width: 1900px;

  table {
    width: 100%;
    border: 1px solid #eaeaea;
    text-align: left;
    margin-bottom: var(--margin-xl);

    th {
      text-align: left;
      padding: 10px;
    }
    tr {
      transition: ease-in-out all 0.2s;
      &:nth-child(odd) {
        td {
          background: #FAFAFA;
        }
      }
      &:hover {
        td {
          background: #f1f3fa;
        }
      }

      &.highlight {
        td {
          background: #caffce;
        }
      }
    }
    td {
      padding: 10px;
    }

  }
  .site-address {
    background: #f1f3fa;
    padding: var(--padding-s) var(--padding-l);
    dl {
      display: flex;
      justify-content: flex-start;

      dt {
        font-weight: bold;
        min-width: 150px
      }

      dd {
      }
    }
  }
}
</style>