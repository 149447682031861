import BookingNotices from "./BookingNotices"
import NoticeTemplateBuilderIcon from "./components/NoticeTemplateBuilderIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

export default {
  moduleGrouping: "Management",
  name: "NoticeTemplateBuilder",
  menuName: "Booking Notices",
  component: BookingNotices,
  path: "BookingNotices",
  icon: NoticeTemplateBuilderIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View Booking Notices data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("BookingNotices", BookingNotices)
    Vue.component("NoticeTemplateBuilderIcon", NoticeTemplateBuilderIcon)
  }
}
