export default {
  fields: {
    templateDetails: {
      siteId: {
        type: "entity-select",
        props: {
          entity: "Site",
          filterable: true,
          clearable: true,
        },
        label: "Select Site",
        value: null,
        validation: [{required: true, message: "Please select a site", trigger: "blur"}]
      },
      description: {
        type: "textarea",
        value: null,
        label: "Description",
        placeholder: "This is used for the notice letter",
        validation: [{required: true, message: "Please enter description", trigger: "blur"}]
      },
      date: {
        width: 8,
        type: "date",
        value: null,
        label: "Inspection Date",
        props: {
          format: "dd/MM/yyyy",
        },
        validation: [{required: true, message: "Please enter a date", trigger: "blur"}]
      },
      startTime: {
        width: 8,
        type: "time",
        props: {
          format: "hh:mm A",
          valueFormat: 'HH:mm:ss'
        },
        label: "Inspection Start",
        validation: [{required: true, message: "Please enter a start time", trigger: "blur"}]
      },
      endTime: {
        width: 8,
        type: "time",
        props: {
          format: "hh:mm A",
          valueFormat: 'HH:mm:ss'
        },
        label: "Inspection End",
        validation: [{required: true, message: "Please enter a finish time", trigger: "blur"}]
      },
      bookingInactiveDate: {
        width: 8,
        type: "date",
        props: {
          format: "dd/MM/yyyy",
        },
        label: "Inspection Inactive",
        validation: [{required: true, message: "Please enter an inactive time", trigger: "blur"}]
      },
      placeHolder: {
        type: "placeholder"
      },
      timeBlocks: {
        width: 24,
        type: "time-block-selector",
        props: {
          value: ''
        }
      }
    },
  }
}
