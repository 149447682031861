import BookingSummaryView from "./views/BookingSummary"
import ThankYouView from "./views/ThankYouView";

const routes = [
  {
    path: "thank-you",
    name: "thank-you-view",
    component: ThankYouView
  },
  {
    path: "booking/:id",
    name: "booking-summary-view",
    component: BookingSummaryView
  },
]

export default routes