import BookingNoticeTableView from "./views/BookingNoticeTableView"
import BookingsOverview from "./views/BookingsOverview";

const routes = [
  {
    path: "",
    name: "booking-noticer-list-view",
    component: BookingNoticeTableView
  },
  {
    path: ":id",
    name: "bookings-overview",
    component: BookingsOverview
  }
]

export default routes