export default {
  fields: {
    siteDetails: {
      title: {
        type: "text",
        value: null,
        label: "Site Title",
        placeholder: "ie. Michael Hill AU",
        validation: [{required: true, message: "Please enter site title", trigger: "blur"}]
      },
      notes: {
        type: "textarea",
        value: null,
        label: "Site Notes",
        placeholder: "ie. access through the back door"
      },
      // status: {
      //   type: "switch",
      //   value: 'active',
      //   label: "Activate Site",
      // }
    },
    addressDetails: {
      street: {
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 123 Street"
      },
      city: {
        type: "text",
        value: null,
        label: "City",
        placeholder: "Sydney"
      },
      zip: {
        type: "text",
        value: null,
        label: "Postcode",
        placeholder: "ie. 2000"
      },
      state: {
        type: "text",
        value: null,
        label: "State",
        placeholder: "ie. NSW",
        initialValue: "NSW"
      },
      country: {
        type: "text",
        value: null,
        label: "Country",
        placeholder: "ie. Australia",
        initialValue: "Australia"
      }
    },
    contactDetails: {
      contactType: {
        type: "text",
        value: null,
        label: "Contact Type",
        placeholder: "ie. Site Manager"
      },
      contactName: {
        type: "text",
        value: null,
        label: "Contact Name",
        placeholder: "ie. John Doe"
      },
      contactPhone: {
        type: "text",
        value: null,
        label: "Contact Phone",
        placeholder: ""
      },
      contactEmail: {
        type: "email",
        value: null,
        label: "Contact Email",
        placeholder: ""
      },
    }
  }
}
